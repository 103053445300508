import { createRouter, createWebHashHistory } from "vue-router";
import EventList from "../views/EventList.vue";
import EventDetails from "../views/EventDetails.vue";
import About from "../views/About.vue";
const routes = [{
        path: "/",
        name: "EventList",
        component: EventList,
    },
    {
        path: "/about",
        name: "About",

        component: About
    },
    {
        path: "/event/:id",
        name: "EventDetails",
        props: true,
        component: EventDetails
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;